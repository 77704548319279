import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CSimpleAccordionList,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          title: {
            en: ' OIWAI',
            ja: 'お祝い',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/oiwai/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/oiwai/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotron_keikaen"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: '中国料理「桂花苑」',
                path: '/restaurants/keikaen/',
              },
            ],
            current: {
              label: frontmatter?.title.includes('-')
                ? frontmatter?.title.split('-')[1]
                : frontmatter?.title,
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ sub: '14名〜40名', main: '貸切会食' }}
          />
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/oiwai/img_oiwai.png',
                  alt: '',
                },
                title: (
                  <>
                    大切な方々との想い出を創る
                    <br />
                    貸切での会食プラン
                  </>
                ),
                text: (
                  <>
                    <p>
                      開放感溢れるリゾート環境とホテルの最上階の眺望。美味しい本格的な中国料理を囲みながら大切な方々と想い出のひと時を過ごす。特別な日をもっと特別にする、非日常のお祝い。
                    </p>
                  </>
                ),
                btn: {
                  label: 'MAILでのお問合せ',
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              },
            ]}
          />
          <p className="news_title">お知らせ</p>
          <CSimpleAccordionList
            data={[
              {
                title: <>料金・プラン内容</>,
                content: (
                  <>
                    <p className="u_mb30">
                      人数
                      <br />
                      14名～40名
                      <ul className="c_noteList">
                        <li>
                          上記人数以外でのご利用をご希望の際はご相談ください。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      15名での会食のみのお見積り例
                      <br />
                      268,000円～
                      <ul className="c_noteList">
                        <li>料理/飲物1名追加:13,200円〜</li>
                        <li>アルコールフリーの場合:+1,100円</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      内容
                      <ul className="c_circleList">
                        <li>料理</li>
                        <li>飲物（ノンアルコール）</li>
                        <li>レストラン貸切料</li>
                        <li>テーブルクロス/ナフキン</li>
                      </ul>
                    </p>
                    <p className="c_sectLead u_mbMedium u_borderTop">
                      オプション
                    </p>
                    <ul className="c_circleList">
                      <li>フォトウェディング:192,500円～</li>
                      <li>ウェディングケーキ:1名1,518円～</li>
                      <li>お子様料理:1名1,895円～</li>
                      <li>液晶ディスプレイ60インチ:33,000円</li>
                      <li>音響機材一式:38,500円</li>
                      <li>卓上装花:7,150円～</li>
                      <li>衣装（ドレス/タキシード/小物）:316,800円～</li>
                      <li>新郎新婦お支度:33,000円～</li>
                      <li>介添え:22,000円～</li>
                      <li>ブーケ:5,500円～</li>
                    </ul>
                    <ul className="c_noteList">
                      <li>
                        上記内容以外でのご利用をご希望の際はご相談ください。
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
            exClass="u_mbExLarge"
          />
          <CSectTitle03
            exClass="u_mtExLarge"
            type="en"
            title={{ sub: '4名〜16名', main: '個室顔合わせ' }}
          />
          <CGeneralMedia
            reverse={true}
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/oiwai/img_oiwai02.png',
                  alt: '',
                },
                title: (
                  <>
                    新しい家族との絆を深める
                    <br />
                    個室での顔合わせプラン
                  </>
                ),
                text: (
                  <>
                    <p>
                      心地良い店内の寛げる個室でご両家の親睦を深める顔合わせ。会話が弾む、笑顔が溢れる美味しい桂花苑の料理と新しい家族との時間を愉しむお祝い。
                    </p>
                  </>
                ),
                btn: {
                  label: 'MAILでのお問合せ',
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              },
            ]}
          />
          <p className="news_title">お知らせ</p>
          <CSimpleAccordionList
            data={[
              {
                title: <>プラン内容・料金</>,
                content: (
                  <>
                    <p className="u_mb30">
                      人数
                      <br />
                      4名～16名
                      <ul className="c_noteList">
                        <li>
                          上記人数以外でのご利用をご希望の際はご相談ください。
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      料金
                      <br />
                      4名での会食のみのお見積り例
                      <br />
                      41,200円～
                      <ul className="c_noteList">
                        <li>料理1名追加:8,800円〜</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      内容
                      <ul className="c_circleList">
                        <li>料理（アニバーサリーコース1）</li>
                        <li>個室料</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>飲物は当日承ります。</li>
                      </ul>
                    </p>
                    <p className="c_sectLead u_borderTop">オプション</p>
                    <p className="u_mb30">
                      料金
                      <ul className="c_circleList">
                        <li>お子様料理:1,895円～</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      結納品／筆耕料
                      <ul className="c_circleList">
                        <li>
                          結納品九品目（金包付） 33,000円
                          <br />
                          筆耕料 2,750円
                        </li>
                        <li>
                          受書5点セット（金包付） 18,700円
                          <br />
                          筆耕料 2,750円
                        </li>
                        <li>
                          受書３点セット（金包付） 16,500円
                          <br />
                          筆耕料 2,750円
                        </li>
                        <li>
                          箱型金包セット 9,350円
                          <br />
                          筆耕料 1,320円
                        </li>
                        <li>
                          記念品目録 2,200円
                          <br />
                          筆耕料 1,650円
                        </li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      着付け
                      <ul className="c_circleList">
                        <li>振袖着付け:14,300円</li>
                        <li>留袖着付け:12,100円</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      ヘア＆メイク
                      <ul className="c_circleList">
                        <li>ヘアセット＆メイク:12,100円</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      写真
                      <ul className="c_circleList">
                        <li>アニバーサリーフォト 5,500円～</li>
                        <li>
                          スタジオ写真
                          <br />
                          1カット2L台紙仕上げデータ付 9,900円
                          <br />
                          六つ切りデータ付（2冊納品） 17,600円
                          <br />
                          四つ切りデータ付（2冊納品） 25,300円
                        </li>
                        <li>フォトツアーズ 33,000円</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>館内やガーデンにて30分撮影</li>
                        <li>30カットデータ納品</li>
                        <li>1カット2L台紙仕上げ</li>
                      </ul>
                    </p>
                  </>
                ),
              },
            ]}
            exClass="u_mbExLarge"
          />
          <ul className="c_noteList">
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              ザ
              クラブ・ロイヤルパークホテルズ会員割引・ポイント付与対象となります。個室料やその他付帯商品は対象外となります。
            </li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ main: 'GUIDE', sub: '営業時間｜ご利用案内' }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
